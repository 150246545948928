.project {
	max-width: 370px;
	background-color: var(--project-card-bg);
	box-shadow: var(--box-shadow);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.project__img {
	border-radius: 10px;
	border-bottom: 1px solid var(--project-card-border);
	object-fit: contain;
	height: 100%;
	max-height: 300px;
}

.project__title {
	padding: 15px 20px 25px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;
	color: var(--text-color);
	flex: 1 1 auto;
}